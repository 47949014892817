<template>
  <vertical-modal>
    <div class="flex flex-col py-20 px-10">
      <div class="flex flex-col">
        <div class="grid grid-cols-3 py-5 border-b-2 border-salmon border-opacity-30 w-full">
          <div class="text-black text-opacity-70">Data</div>
          <div class="col-start-2 col-end-3 text-black">{{ getDate(item.created_at).format('DD/MM/YYYY HH:mm') }}</div>
        </div>
        <div class="grid grid-cols-3 border-b-2 border-salmon border-opacity-30 py-5 w-full" v-if="item.user">
          <div class="text-black text-opacity-70">Usuário</div>
          <div class="col-start-2 col-end-3">
            <div class="text-black text-opacity-70">{{ item.user.name }}</div>
            <div class="text-black text-sm text-opacity-50">{{ item.user.email }}</div>
          </div>
        </div>
        <div class="grid grid-cols-3 border-b-2 border-salmon border-opacity-30 py-5 w-full">
          <div class="text-black text-opacity-70">Avaliação</div>
          <rating :value="item.review" />
        </div>
        <div class="grid grid-cols-3 border-b-2 border-salmon border-opacity-30 py-5 w-full">
          <div class="text-black text-opacity-70">Tipo</div>
          <div class="col-start-2 col-end-3 text-black">{{ item.category }}</div>
        </div>

        <div class="grid grid-cols-1 py-5 w-full">
          <div class="text-black text-opacity-70">Comentário</div>
          <div class="text-black italic">
            {{ item.comment }}
          </div>
        </div>
      </div>
    </div>
  </vertical-modal>
</template>

<script>
import { mapState } from 'vuex';

import VerticalModal from '@/components/VerticalModal';
import dayjs from 'dayjs';
import Rating from '@/components/Rating.vue';

export default {
  name: "AdminUser",
  components: {
    Rating,
    VerticalModal,
  },
  props: ['item'],
  computed: {
    ...mapState('verticalModal', ['open']),
  },
  methods: {
    getDate(date) {
      return dayjs(date);
    }
  }
};
</script>

<style lang="scss"></style>
